(function(angular, $){
  'use strict';

  angular.module('area', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.area', {
            url: '/area-protegida/:areaId',
            abstract: true,
            templateUrl: 'app/modules/area/main.tpl.html',
            resolve: {
              area: ['$q', '$stateParams', 'Areas',
                function($q, $stateParams, Areas){
                  var deferred = $q.defer();
                  Areas.get($stateParams.areaId).then(function(data){
                    if(data){
                      deferred.resolve(data);
                    }else{
                      deferred.reject();
                    }
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ],
              planes: ['$q', '$stateParams', 'Planes',
                function($q, $stateParams, Planes){
                  var deferred = $q.defer();
                  Planes.byArea($stateParams.areaId).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ],
              traslapes: ['$q', '$stateParams', 'Traslapes',
                function($q, $stateParams, Traslapes){
                  var deferred = $q.defer();
                  Traslapes.byArea($stateParams.areaId).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ],
              documents: ['$q', '$stateParams', 'Documentos',
                function($q, $stateParams, Documentos){
                  var deferred = $q.defer();
                  Documentos.byArea($stateParams.areaId).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ]
            },
            controller: 'Area.MainController'
          })
          .state('main.views.area.views', {
            url: '',
            views: {
              intro: {
                templateUrl: 'app/modules/area/intro.tpl.html',
                controller: 'Area.IntroController'
              },
              timeline: {
                templateUrl: 'app/modules/area/timeline.tpl.html',
                controller: 'Area.TimelineController'
              },
              chart: {
                templateUrl: 'app/modules/area/chart.tpl.html',
                controller: 'Area.ChartController'
              },
              documents: {
                templateUrl: 'app/modules/area/documents.tpl.html',
                controller: 'Area.DocumentsController'
              },
              gallery: {
                templateUrl: 'app/modules/area/gallery.tpl.html',
                controller: 'Area.GalleryController'
              }
            }
          });
      }
    ])

    .directive('timelineDate', function(){
      return {
        restrict: 'A',
        replace: false,
        scope: {
          item: '=timelineDate',
          index: '@'
        },
        template: '<a href="#0" ng-attr-data-date="{{item.date}}" class="border-after-red bg-after-red" ng-class="class">{{item.label}}</a>',

        link: function(scope, element, attrs){
          if(parseInt(scope.index, 10) === 0){
            scope.class = 'selected';
          }
        }
      };
    })

    .directive('timelineItem', function(){
      return {
        restrict: 'A',
        replace: true,
        scope: {
          item: '=timelineItem',
          index: '@'
        },
        templateUrl: 'app/modules/area/timeline_item.tpl.html',

        link: function(scope, element, attrs){
          if(parseInt(scope.index, 10) === 0){
            scope.class = 'selected';
          }
        }
      };
    })

    .controller('Area.MainController', ['$scope', 'ServerRequest',
      function($scope, ServerRequest){
        // Valores iniciales
        $scope.common = {
          model: {},
          documentsSR: new ServerRequest()
        };
      }
    ])

    // @deprecated
    // .controller('Area.IntroController', ['$scope', '$timeout', 'ServerRequest', 'MathService', 'Organizaciones', 'backend', 'area', 'traslapes', 'organizaciones',
    //   function($scope, $timeout, ServerRequest, MathService, Organizaciones, backend, area, traslapes, organizaciones){
    .controller('Area.IntroController', ['$scope', '$timeout', 'ServerRequest', 'MathService', 'Organizaciones', 'backend', 'area', 'organizaciones',
      function($scope, $timeout, ServerRequest, MathService, Organizaciones, backend, area, organizaciones){
        debug.info('Area.IntroController', 'area', area, 'organizaciones', organizaciones);

        $scope.area = area;

        $scope.organizacionArea = _.find(organizaciones, {id: area.organizacion_id});
        if(!$scope.organizacionArea){
          Organizaciones.get(area.organizacion_id)
            .then(function(organizacion){
              $scope.organizacionArea = organizacion;
            });
        }

        $scope.areasChartsSR = new ServerRequest();
        $scope.mapFlag = false;

        // @deprecated
        // var organizaciones = _.filter(traslapes, {categoria: 'ORGANIZACION'});
        // $scope.organizaciones = _.pluck(organizaciones, 'termino');

        // Calcula los valores de la grafica de geografica
        var geografica = {
          terrestre: area.area.total.geografica ? area.area.total.geografica.terrestre : 0,
          maritima: area.area.total.geografica ? area.area.total.geografica.maritima: 0,
        };
        geografica.total = geografica.terrestre + geografica.maritima;
        geografica.porcentajes = {
          terrestre: MathService.round((geografica.terrestre / geografica.total) * 100),
          maritima: MathService.round((geografica.maritima / geografica.total) * 100)
        };
        $scope.geografica = geografica;

        // Calcula los valores de la grafica de resolucion
        var acto = {
          terrestre: area.area.total.acto.terrestre,
          maritima: area.area.total.acto.maritima
        };
        acto.total = acto.terrestre + acto.maritima;
        acto.porcentajes = {
          terrestre: MathService.round((acto.terrestre / acto.total) * 100),
          maritima: MathService.round((acto.maritima / acto.total) * 100)
        };
        $scope.acto = acto;

        // Calcula los valores de la grafica de geografica vs resolucion
        var geograficaActo = {
          geografica: geografica.total,
          acto: acto.total
        };
        geograficaActo.total = geograficaActo.geografica + geograficaActo.acto;
        geograficaActo.porcentajes = {
          geografica: MathService.round((geograficaActo.geografica / geograficaActo.total) * 100),
          acto: MathService.round((geograficaActo.acto / geograficaActo.total) * 100)
        };
        $scope.geograficaActo = geograficaActo;

        // Graficas de areas
        $scope.areasChartsSR.start();
        $timeout(function(){
          $scope.areasChartsSR.success();
          $scope.chart1 = {
            id: 'chart1',
            values: [geografica.terrestre, geografica.maritima],
            colors: ['#fe9927', '#cd2d2e']
          };
          $scope.chart2 = {
            id: 'chart2',
            values: [acto.terrestre, acto.maritima],
            colors: ['#6ca754', '#72a9db']
          };
          $scope.chart3 = {
            id: 'chart3',
            values: [geograficaActo.geografica, geograficaActo.acto],
            colors: ['#e66101', '#5e3c99']
          };
        });

        const options = {
          scale: true,
          baseMap: {
            esriApiKey:
              'AAPKb10821df102a46a4b930958d7a6a06593sdla7i0cMWoosp7XXlYflDTAxsZMUq-oKvVOaom9B8CokPvJFd-sE88vOQ2B_rC',
            esriBasemap: true,
            esriVectorBasemap: false,
            googleBasemap: false,
            OSMBasemap: false,
            position: 'bottomright',
          },
          toolbar: true,
          mapOptions: {
            // Para quitar control de zoom
            zoomControl: true,
            // Para deshabilitar zoom al hacer scroll con mouse controles de zoom y extension
            scrollWheelZoom: false,
            maxZoom: 16,
            minZoom: 5,
          },
          showButton: {
            layers: true,
            // addLayer: true,
            identify: false,
            // markers: true,
            // draw: true,
            // filter: true,
            // toolbox: true,
          },
          mapEvents: {
            popupOnClick: true,
          },
        };

        const layers = [
          {
            url: 'https://mapas.parquesnacionales.gov.co/services/pnn/wms',
            type: 'wms',
            name: 'Runap',
            visible: true,
            topic: 'Runap',
            order: 2,
            description: 'Capa de Runap',
            styles: 'pnn:limites_parques_etiqueta',
            options: {
              layers: 'pnn:runap',
              query: `ap_id = '${area.id}'`,
              transparent: true,
              format: 'image/png',
            },
          },
          {
            url: 'https://mapas.parquesnacionales.gov.co/services/pnn/wms',
            type: 'wms',
            name: 'Departamentos',
            visible: false,
            topic: 'Político',
            order: 1,
            description: 'Capa de departamentos',
            options: {
              layers: 'pnn:departamentos',
              transparent: true,
              format: 'image/png',
            },
          },
        ];

        const visor = new Visor({
          container: 'map',
          options,
          layers,
        });

        // Dispara este evento cuando ha cargado todas las capas por defecto del visor
        visor.map.on('ready', () => {
          // @TODO: pedirle a Juan Camilo arreglar este machete
          visor.baseMapSelector.setBasemap({
            type: 'ESRI',
            key: 'Gray',
          });
          // @TODO: pedirle a Juan Camilo arreglar este machete
          visor.map.removeControl(visor.baseMapSelector.control);

          const layer = visor.findLayerByName('Runap');
          visor.zoomToLayerByAttribute(layer, 'ap_id', area.id);

          visor.setSelectedLayer('Runap');
        });

        // Opciones de swiper
        $scope.swiperOptions = {
          autoHeight: true,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          spaceBetween: 30,
          loop: true,
          autoplay: 5000,
          // Disable preloading of all images
          preloadImages: false,
          // Enable lazy loading
          lazyLoading: true
        };

        $scope.changeView = function(view){
          if(view === 'map' && !$scope.mapFlag){
            $scope.mapFlag = true;
          }
        };
      }
    ])

    .controller('Area.TimelineController', ['$scope', '$filter', '$timeout', 'documents',
      function($scope, $filter, $timeout, documents){
        // No puede haber mas de un elemento con la misma fecha para que muestre
        // bien la linea de tiempo
        var uniqueDocuments = _.uniq(documents, function(document){
          return document.fecha;
        });
        var timelineItems = _.map(uniqueDocuments, function(document){
          var date = new Date(document.fecha * 1000);
          var dateStr = $filter('date')(date, 'dd/MM/yyyy');
          return {
            date: dateStr,
            label: dateStr
          };
        });
        angular.forEach(timelineItems, function(item){
          item.documents = _.filter(documents, function(document){
            var date = new Date(document.fecha * 1000);
            var dateStr = $filter('date')(date, 'dd/MM/yyyy');
            return (dateStr === item.date);
          });
        });
        $scope.timelineItems = timelineItems;

        if(timelineItems.length){
          $timeout(function(){
            var timelines = $('.cd-horizontal-timeline');
            (timelines.length > 0) && initTimeline(timelines, 80);
          });
        }
      }
    ])

    .controller('Area.ChartController', ['$scope', '$timeout', 'MathService', 'ServerRequest', /*'Charts',*/ 'traslapes',
      function($scope, $timeout, MathService, ServerRequest, /*Charts,*/ traslapes){
        $scope.chartSR = new ServerRequest();

        // Obtiene los tipos de graficas
        var categories = _.map(traslapes, function(item){
          var label;
          if(item.categoria === 'SISTEMA_NATURAL'){
            if(item.clase === 'BIOGEOGRAFICO'){
              label = 'Biogeográficos';
            }else if(item.clase === 'BIOMA'){
              label = 'Biomas';
            }else if(item.clase === 'ECOSISTEMA'){
              label = 'Ecosistemas';
            }
          }else if(item.categoria === 'CLASIFICACION'){
            if(item.clase === 'UICN'){
              label = 'Categorías UICN';
            }
          }else if(item.categoria === 'DIVISION_POLITICA'){
            if(item.clase === 'DEPARTAMENTO'){
              label = 'Departamentos';
            }else if(item.clase === 'MUNICIPIO'){
              label = 'Municipios';
            }
          }else if(item.categoria === 'OBJETIVO'){
            if(item.clase === 'GENERAL'){
              label = 'Objetivos generales';
            }else if(item.clase === 'ESPECIFICO'){
              label = 'Objetivos específicos';
            }
          }else if(item.categoria === 'ORGANIZACION'){
            if(item.clase === 'CAR'){
              label = 'CARs';
            }else{
              label = 'Organizaciones';
            }
          }else if(item.categoria === 'TRASLAPE'){
            if(item.clase === 'AREA'){
              label = 'Áreas protegidas';
            }
          }else if(item.categoria === 'USO'){
            label = 'Usos';
          }
          if(label){
            return {
              value: _.sprintf('%s.%s', item.categoria, item.clase),
              label: label
            };
          }
          return null;
        });
        categories = _.filter(categories, function(item){
          return !!item;
        });
        categories = _.uniq(categories, function(item){
          return item.value;
        });
        // @TODO: utilizar funcion para que en el ordenamiento no tenga en cuenta las tildes
        categories = _.sortBy(categories, 'label');
        if(categories.length){
          // Selecciona el boton inicial
          $scope.common.model.chart = categories[0].value;
        }
        if (categories) {
          // @TODO
        }
        $scope.charts = categories;

        var generateChart = function(chart){
          var chartOptions = {
            title: {
              text: chart.label
            },
            subtitle: {
              text: '(hectáreas)'
            },
            colorAxis: {
              minColor: '#FFFFFF',
              maxColor: '#7CB5EC'
            },
            tooltip: {
              formatter: function(){
                var key;
                if(this.key.length > 30){
                  key = _.truncate(this.key, 30);
                }else{
                  key = this.key;
                }
                return _.sprintf('<strong>%s:</strong> %s ha', key, this.point.value);
              }
            },
            series: [{
              type: 'treemap',
              layoutAlgorithm: 'squarified',
              data: []
            }]
          };

          var terminos = _.filter(traslapes, function(termino){
            return (_.sprintf('%s.%s', termino.categoria, termino.clase) === chart.value);
          });
          angular.forEach(terminos, function(termino){
            var value = 0;
            angular.forEach(termino.area.geografica, function(item){
              if(item){
                value += parseFloat(item);
              }
            });
            var color = MathService.round(value);
            value = parseFloat(MathService.toPrecision(value, 4));
            chartOptions.series[0].data.push({
              name: termino.termino,
              // name: (termino.categoria == 'TRASLAPE' && termino.clase == 'AREA') ? termino.traslape.??? : termino.termino,
              value: value,
              colorValue: color
            });
          });
          debug.info('terminos', terminos, 'serie', chartOptions.series[0].data);

          $scope.chartOptions = chartOptions;
        };

        $scope.changeChart = function(chart){
          // Consulta las opciones del chart
          /*$scope.chartSR.start();
          Charts.get(chart).then(function(data){
            $scope.chartSR.success();
            $scope.chartOptions = data;
          }, function(response){
            $scope.chartSR.setErrorResponse(response);
          });*/

          $scope.chartSR.start();
          $timeout(function(){
            var chartItem = _.find($scope.charts, {value: chart});
            generateChart(chartItem);
            $scope.chartSR.success();
          });
        };

        if ($scope.charts.length) {
          $scope.changeChart($scope.charts[0].value);
        }
      }
    ])

    .controller('Area.DocumentsController', ['$scope', 'area', 'documents','planes',
      function($scope, area, documents, planes){
        var categoryIds, categories = [{id: 'shapes', name: 'Shapes'},{id: 'planes', name: 'Instrumentos de planeación'}], i, j;
        categoryIds = _.uniq(_.pluck(documents, 'tipo_id'));
        for(i in categoryIds){
          for(j in documents){
            if(documents[j].tipo_id === categoryIds[i]){
              categories.push({
                id: documents[j].tipo_id,
                name: documents[j].tipo
              });
              break;
            }
          }
        }
        categories = _.sortBy(categories, 'name');
        var files = [];
        angular.forEach(categories, function(category){
          if(category.id === 'shapes'){
            files.push({
              category: category.name,
              files: [area.shape]
            });
          }else if(category.id === 'planes'){
            files.push({
              category: category.name,
              files: planes
            });
          }else{
            files.push({
              category: category.name,
              files: documents.filter((item)=>{
                return item.tipo_id == category.id;
              })
            });
          }
        });
        $scope.files = files;
      }
    ])

    .controller('Area.GalleryController', ['$scope', 'ServerRequest', 'Imagenes', 'area',
      function($scope, ServerRequest, Imagenes, area){
        $scope.imagesSR = new ServerRequest();

        $scope.galleryOptions = {
          gallery_theme: 'tiles',
            tiles_type: 'justified',
          tile_show_link_icon: true
        };

        // Consulta las imagenes del carousel
        $scope.imagesSR.start();
        Imagenes.byArea(area.id).then(function(data){
          $scope.imagesSR.success();
          $scope.images = data.items;
        }, function(response){
          $scope.imagesSR.setErrorResponse(response);
        });
      }
    ]);
}(window.angular, window.$));
