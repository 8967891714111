(function(angular, $){
  'use strict';

  angular.module('category', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.category', {
            url: '/categoria/:class/:categoryId',
            abstract: true,
            templateUrl: 'app/modules/category/main.tpl.html',
            resolve: {
              categoria: ['$q', '$stateParams', 'categorias',
                function($q, $stateParams, categorias){
                  var deferred = $q.defer();
                  var categoryId;
                  if($stateParams.class === 'UICN'){
                    var categoryIds = $stateParams.categoryId.split(',');
                    categoryId = categoryIds[0];
                  }else{
                    categoryId = $stateParams.categoryId;
                  }
                  var categoria = _.find(categorias, {id: parseInt(categoryId, 10)});
                  if(categoria){
                    deferred.resolve(categoria);
                  }else{
                    deferred.reject();
                  }
                  return deferred.promise;
                }
              ],
              traslapes: ['$q', '$stateParams', 'Traslapes',
                function($q, $stateParams, Traslapes){
                  var deferred = $q.defer();
                  var params = {
                    categoria: 'CLASIFICACION',
                    clase: $stateParams.class,
                    termino_id: $stateParams.categoryId
                  };
                  Traslapes.areas(params).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ],
              categoriaTraslapes: ['$q', '$stateParams', 'Traslapes',
                function($q, $stateParams, Traslapes){
                  var deferred = $q.defer();
                  Traslapes.byCategory($stateParams.categoryId).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ]
              // @TODO: borrar
              /*documents: ['$q', '$stateParams', 'Documentos',
                function($q, $stateParams, Documentos){
                  var deferred = $q.defer();
                  Documentos.byCategory($stateParams.categoryId).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ]*/
            },
            controller: 'Category.MainController'
          })
          .state('main.views.category.views', {
            url: '',
            views: {
              intro: {
                templateUrl: 'app/modules/category/intro.tpl.html',
                controller: 'Category.IntroController'
              },
              // @TODO: borrar
              /*timeline: {
                templateUrl: 'app/modules/category/timeline.tpl.html',
                controller: 'Category.TimelineController'
              },*/
              chart: {
                templateUrl: 'app/modules/category/chart.tpl.html',
                controller: 'Category.ChartController'
              },
              documents: {
                templateUrl: 'app/modules/category/areas.tpl.html',
                controller: 'Category.AreasController'
              }
            }
          });
      }
    ])

    .controller('Category.MainController', ['$scope',
      function($scope){
        // Valores iniciales
        $scope.common = {model: {}};
      }
    ])

    .controller('Category.IntroController', ['$scope', '$stateParams', '$timeout', 'ServerRequest', 'MathService', 'Imagenes', 'backend', 'categoria', 'traslapes',
      function($scope, $stateParams, $timeout, ServerRequest, MathService, Imagenes, backend, categoria, traslapes){
        $scope.categoria = categoria;
        $scope.numberAreas = traslapes.length;

        if($stateParams.class === 'UICN'){
          $scope.categoryName = $scope.categoria.uicn;
        }else{
          $scope.categoryName = $scope.categoria.termino;
        }

        $scope.areasChartsSR = new ServerRequest();
        $scope.imagesSR = new ServerRequest();
        $scope.mapFlag = false;

        // Calcula los valores de la grafica de geografica
        var geografica = {terrestre: 0, maritima: 0};
        angular.forEach(traslapes, function(item){
          geografica.terrestre += item.area.geografica.terrestre;
          geografica.maritima += item.area.geografica.maritima;
        });
        geografica.total = geografica.terrestre + geografica.maritima;
        geografica.porcentajes = {
          terrestre: MathService.round((geografica.terrestre / geografica.total) * 100),
          maritima: MathService.round((geografica.maritima / geografica.total) * 100)
        };
        $scope.geografica = geografica;

        // Calcula los valores de la grafica de resolucion
        var acto = {terrestre: 0, maritima: 0};
        angular.forEach(traslapes, function(item){
          acto.terrestre += item.area.acto.terrestre;
          acto.maritima += item.area.acto.maritima;
        });
        acto.total = acto.terrestre + acto.maritima;
        acto.porcentajes = {
          terrestre: MathService.round((acto.terrestre / acto.total) * 100),
          maritima: MathService.round((acto.maritima / acto.total) * 100)
        };
        $scope.acto = acto;

        // Calcula los valores de la grafica de geografica vs resolucion
        var geograficaActo = {
          geografica: geografica.total,
          acto: acto.total
        };
        geograficaActo.total = geograficaActo.geografica + geograficaActo.acto;
        geograficaActo.porcentajes = {
          geografica: MathService.round((geograficaActo.geografica / geograficaActo.total) * 100),
          acto: MathService.round((geograficaActo.acto / geograficaActo.total) * 100)
        };
        $scope.geograficaActo = geograficaActo;

        // Graficas de areas
        $scope.areasChartsSR.start();
        $timeout(function(){
          $scope.areasChartsSR.success();
          $scope.chart1 = {
            id: 'chart1',
            values: [geografica.terrestre, geografica.maritima],
            colors: ['#fe9927', '#cd2d2e']
          };
          $scope.chart2 = {
            id: 'chart2',
            values: [acto.terrestre, acto.maritima],
            colors: ['#6ca754', '#72a9db']
          };
          $scope.chart3 = {
            id: 'chart3',
            values: [geograficaActo.geografica, geograficaActo.acto],
            colors: ['#e66101', '#5e3c99']
          };
        });

        debug.info('visor category', categoria);

        const options = {
          scale: true,
          baseMap: {
            esriApiKey:
              'AAPKb10821df102a46a4b930958d7a6a06593sdla7i0cMWoosp7XXlYflDTAxsZMUq-oKvVOaom9B8CokPvJFd-sE88vOQ2B_rC',
            esriBasemap: true,
            esriVectorBasemap: false,
            googleBasemap: false,
            OSMBasemap: false,
            position: 'bottomright',
          },
          toolbar: true,
          mapOptions: {
            // Para quitar control de zoom
            zoomControl: true,
            // Para deshabilitar zoom al hacer scroll con mouse controles de zoom y extension
            scrollWheelZoom: false,
            maxZoom: 16,
            minZoom: 5,
          },
          showButton: {
            layers: true,
            // addLayer: true,
            identify: false,
            // markers: true,
            // draw: true,
            // filter: true,
            // toolbox: true,
          },
          mapEvents: {
            popupOnClick: true,
          },
        };

        const layers = [
          {
            url: 'https://mapas.parquesnacionales.gov.co/services/pnn/wms',
            type: 'wms',
            name: 'Runap',
            visible: true,
            topic: 'Runap',
            order: 2,
            description: 'Capa de Runap',
            styles : 'pnn:limites_parques_etiqueta',
            options: {
              layers: 'pnn:runap',
              query: `ap_categoria LIKE '${categoria.termino}'`,
              transparent: true,
              format: 'image/png',
            },
          },
          {
            url: 'https://mapas.parquesnacionales.gov.co/services/pnn/wms',
            type: 'wms',
            name: 'Departamentos',
            visible: false,
            topic: 'Político',
            order: 1,
            description: 'Capa de departamentos',
            options: {
              layers: 'pnn:departamentos',
              transparent: true,
              format: 'image/png',
            },
          },
        ];

        const visor = new Visor({
          container: 'map',
          options,
          layers,
        });

        // Dispara este evento cuando ha cargado todas las capas por defecto del visor
        visor.map.on('ready', () => {
          // @TODO: pedirle a Juan Camilo arreglar este machete
          visor.baseMapSelector.setBasemap({
            type: 'ESRI',
            key: 'Gray',
          });
          // @TODO: pedirle a Juan Camilo arreglar este machete
          visor.map.removeControl(visor.baseMapSelector.control);

          visor.map.fitBounds([
            [-4.227, -82.71],
            [13.393, -66.876],
          ]);

          visor.setSelectedLayer('Runap');
        });

        // Opciones de swiper
        $scope.swiperOptions = {
          autoHeight: true,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          spaceBetween: 30,
          loop: true,
          autoplay: 5000,
          // Disable preloading of all images
          preloadImages: false,
          // Enable lazy loading
          lazyLoading: true
        };

        // Consulta las imagenes del carousel
        $scope.imagesSR.start();
        Imagenes.byCategory(categoria.id).then(function(data){
          $scope.imagesSR.success();
          $scope.images = data.items;
        }, function(response){
          $scope.imagesSR.setErrorResponse(response);
        });
      }
    ])

    // @TODO: borrar
    /*.controller('Category.TimelineController', ['$scope', '$filter', '$timeout', 'documents',
      function($scope, $filter, $timeout, documents){
        // No puede haber mas de un elemento con la misma fecha para que muestre
        // bien la linea de tiempo
        var uniqueDocuments = _.uniq(documents, function(document){
          return document.fecha;
        });
        var timelineItems = _.map(uniqueDocuments, function(document){
          var date = new Date(document.fecha * 1000);
          date.setDate(date.getDate() + 1);
          var dateStr = $filter('date')(date, 'dd/MM/yyyy');
          return {
            date: dateStr,
            label: dateStr
          };
        });
        angular.forEach(timelineItems, function(item){
          item.documents = _.filter(documents, function(document){
            var date = new Date(document.fecha * 1000);
            date.setDate(date.getDate() + 1);
            var dateStr = $filter('date')(date, 'dd/MM/yyyy');
            return (dateStr === item.date);
          });
        });
        $scope.timelineItems = timelineItems;

        if(timelineItems.length){
          $timeout(function(){
            var timelines = $('.cd-horizontal-timeline');
            (timelines.length > 0) && initTimeline(timelines, 80, 240);
          });
        }
      }
    ])*/

    .controller('Category.ChartController', ['$scope', '$timeout', 'MathService', 'StringService', 'ServerRequest', /*'Charts',*/ 'traslapes', 'categoriaTraslapes',
      function($scope, $timeout, MathService, StringService, ServerRequest, /*Charts,*/ traslapes, categoriaTraslapes){
        $scope.chartSR = new ServerRequest();

        // Obtiene los tipos de graficas
        var categories = _.map(categoriaTraslapes, function(item){
          var label;
          if(item.categoria === 'SISTEMA_NATURAL'){
            if(item.clase === 'BIOGEOGRAFICO'){
              label = 'Biogeográficos';
            }else if(item.clase === 'BIOMA'){
              label = 'Biomas';
            }else if(item.clase === 'ECOSISTEMA'){
              label = 'Ecosistemas';
            }
          }else if(item.categoria === 'CLASIFICACION'){
            if(item.clase === 'UICN'){
              label = 'Categorías UICN';
            }
          }else if(item.categoria === 'DIVISION_POLITICA'){
            if(item.clase === 'DEPARTAMENTO'){
              label = 'Departamentos';
            }else if(item.clase === 'MUNICIPIO'){
              label = 'Municipios';
            }
          }else if(item.categoria === 'OBJETIVO'){
            if(item.clase === 'GENERAL'){
              label = 'Objetivos generales';
            }else if(item.clase === 'ESPECIFICO'){
              label = 'Objetivos específicos';
            }
          }else if(item.categoria === 'ORGANIZACION'){
            if(item.clase === 'CAR'){
              label = 'CARs';
            }else{
              label = 'Organizaciones';
            }
          }else if(item.categoria === 'TRASLAPE'){
            if(item.clase === 'AREA'){
              label = 'Áreas protegidas';
            }
          }else if(item.categoria === 'USO'){
            label = 'Usos';
          }
          if(label){
            return {
              value: _.sprintf('%s.%s', item.categoria, item.clase),
              label: label
            };
          }
          return null;
        });
        categories = _.filter(categories, function(item){
          return !!item;
        });
        categories = _.uniq(categories, function(item){
          return item.value;
        });
        categories.push({
          value: 'TRASLAPE.AREA',
          label: 'Áreas protegidas'
        });
        categories = _.sortBy(categories, function(item){
          return StringService.normalize(item.label);
        });
        if(categories.length){
          // Selecciona el boton inicial
          $scope.common.model.chart = categories[0].value;
        }
        $scope.charts = categories;

        var generateChart = function(chart){
          var chartOptions = {
            title: {
              text: chart.label
            },
            subtitle: {
              text: '(hectáreas)'
            },
            colorAxis: {
              minColor: '#FFFFFF',
              maxColor: '#7CB5EC'
            },
            tooltip: {
              formatter: function(){
                var key;
                if(this.key.length > 30){
                  key = _.truncate(this.key, 30);
                }else{
                  key = this.key;
                }
                return _.sprintf('<strong>%s:</strong> %s ha', key, this.point.value);
              }
            },
            series: [{
              type: 'treemap',
              layoutAlgorithm: 'squarified',
              data: []
            }]
          };

          debug.info('generateChart', chart);
          var terminos;
          if(chart.value === 'TRASLAPE.AREA'){
            terminos = traslapes;
            angular.forEach(terminos, function(termino){
              var value = 0;
              angular.forEach(termino.area.geografica, function(item){
                if(item){
                  value += parseFloat(item);
                }
              });
              var color = MathService.round(value);
              value = parseFloat(MathService.toPrecision(value, 4));
              chartOptions.series[0].data.push({
                name: termino.contexto_termino,
                value: value,
                colorValue: color
              });
            });
          }else{
            terminos = _.filter(categoriaTraslapes, function(termino){
              return (_.sprintf('%s.%s', termino.categoria, termino.clase) === chart.value);
            });
            angular.forEach(terminos, function(termino){
              var value = 0;
              angular.forEach(termino.area.geografica, function(item){
                if(item){
                  value += parseFloat(item);
                }
              });
              var color = MathService.round(value);
              value = parseFloat(MathService.toPrecision(value, 4));
              chartOptions.series[0].data.push({
                name: termino.termino,
                value: value,
                colorValue: color
              });
            });
          }

          $scope.chartOptions = chartOptions;
        };

        $scope.changeChart = function(chart){
          // Consulta las opciones del chart
          /*$scope.chartSR.start();
          Charts.get(chart).then(function(data){
            $scope.chartSR.success();
            $scope.chartOptions = data;
          }, function(response){
            $scope.chartSR.setErrorResponse(response);
          });*/

          $scope.chartSR.start();
          $timeout(function(){
            var chartItem = _.find($scope.charts, {value: chart});
            generateChart(chartItem);
            $scope.chartSR.success();
          });
        };

        if($scope.charts.length){
          $scope.changeChart($scope.charts[0].value);
        }
      }
    ])

    .controller('Category.AreasController', ['$scope', 'MathService', 'StringService', 'categoria', 'traslapes',
      function($scope, MathService, StringService, categoria, traslapes){
        $scope.categoria = categoria;
        $scope.numberAreas = traslapes.length;

        traslapes = _.sortBy(angular.copy(traslapes), function(item){
          return StringService.normalize(item.contexto_termino);
        });
        $scope.traslapes = _.map(traslapes, function(item){
          var total = item.area.geografica.terrestre + item.area.geografica.maritima;
          item.area.geografica.total = parseFloat(MathService.toPrecision(total, 4));
          return item;
        });
      }
    ]);
}(window.angular, window.$));
